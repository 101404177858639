let isPassiveAllowed;

export default function supportsPassive() {
  if (isPassiveAllowed != null) {
    return isPassiveAllowed;
  }
  try {
    const opts = Object.defineProperty({}, 'passive', {
      // eslint-disable-next-line getter-return
      get: () => {
        isPassiveAllowed = true;
      },
    });
    window.addEventListener('testPassive', null, opts);
    window.removeEventListener('testPassive', null, opts);
  } catch (e) {
    isPassiveAllowed = false;
  }

  return isPassiveAllowed;
}
