import React, { useRef } from 'react';
import { Link } from '@reach/router';
// import useOnClickOutside from '../utils/useOnClickOutside';
// import Hamburger from './Hamburger';
import styles from './Nav.module.scss';

/**
 * Static nav
 */
function Nav() {
  const navContainerRef = useRef();
  // const [isOutside, setIsOutside] = useOnClickOutside(navContainerRef);
  // const isToggled = !isOutside;
  // const toggleMenu = () => setIsOutside(isToggled);

  return (
    <nav className={styles.nav}>
      <div className={styles.navbar} ref={navContainerRef}>
        <Link className={styles.brand} to="/">
          Stonks Trading
        </Link>
        {/*<div className={styles.button}>
        <Hamburger
            isToggled={isToggled}
            onClick={toggleMenu}
            aria-controls="menu"
          />
        </div>*/}
      </div>
    </nav>
  );
}

export default React.memo(Nav);
