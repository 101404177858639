import React, { useRef, useMemo } from 'react';
import ParticleBoard from './ParticleBoard';
import styles from './Home.module.scss';
import stonks from '../img/stonks.jpg';

function Home() {
  const targetRef = useRef();

  // Memoize the entire hero tree to save on computations
  const hero = useMemo(
    () => (
      <section className={styles.hero}>
        <img className={styles.logo} src={stonks} alt="stonks logo" />
        <header className={styles.header}>
          <h1 ref={targetRef} className={styles.title}>
            Stonks Trading
          </h1>
          <div className="h5">A technology-driven trading firm using cutting-edge proprietary blockchain-based machine learning algorithms</div>
          <div>Our founding team brings years of experience from companies like Facebook, Stripe, Twitter and world-class universities</div>
        </header>
        <ParticleBoard targetRef={targetRef} />
      </section>
    ),
    [],
  );

  return (
    <main className={styles.main}>
      {hero}

    </main>
  );
}

export default Home;
