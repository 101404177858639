export default function ParticleFactory(colours, radius, velocity) {
  const diameter = radius * 2;
  let globalCount = 0;
  let width = 0;
  let height = 0;

  let offCanvas = document.createElement('canvas');
  offCanvas =
    'OffscreenCanvas' in window
      ? offCanvas.transferControlToOffscreen()
      : offCanvas;
  const offContext = offCanvas.getContext('2d');
  const pixelRatio = window.devicePixelRatio;
  offContext.scale(pixelRatio, pixelRatio);
  offCanvas.width = colours.length * diameter;
  offCanvas.height = diameter;

  colours.forEach((colour, i) => {
    offContext.fillStyle = colour;
    offContext.beginPath();
    offContext.arc(i * diameter + radius, radius, radius, 0, 2 * Math.PI);
    offContext.closePath();
    offContext.fill();
  });

  function Particle(index) {
    this.o = (globalCount % colours.length) * diameter;
    this.v = 1 + Math.random() * Math.min(velocity, index);
    this.d = Math.floor(diameter * Math.random());
    globalCount++;
  }

  Particle.prototype.draw = function(context) {
    context.drawImage(
      offCanvas, // source
      this.o, // source pos x
      0, // source pos y
      diameter, // source size x
      diameter, // source size y
      Math.floor(this.x), // source pos x
      Math.floor(this.y), // source pos y
      this.d, // dest size x
      this.d, // dest size y
    );
  };

  Particle.prototype.move = function(targetX, targetY) {
    const x = targetX - this.x;
    const y = targetY - this.y;
    const distance = Math.sqrt(x * x + y * y);

    if (distance < 30) {
      this.reposition();
    } else {
      const attrX = x / distance;
      const attrY = y / distance;
      this.x += this.v * attrX;
      this.y += this.v * attrY;
    }
  };

  Particle.prototype.reposition = function() {
    let p = Math.floor(Math.random() * (width * 2 + height * 2));
    if (p < width + height) {
      if (p < width) {
        this.x = p;
        this.y = 0;
      } else {
        this.x = width;
        this.y = p - width;
      }
    } else {
      p = p - (width + height);
      if (p < width) {
        this.x = width - p;
        this.y = height;
      } else {
        this.x = 0;
        this.y = height - (p - width);
      }
    }
  };

  Particle.prototype.positionRandomly = function() {
    this.x = Math.floor(Math.random() * width);
    this.y = Math.floor(Math.random() * height);
  };

  return {
    create(batchIndex) {
      const particle = new Particle(batchIndex);
      particle.positionRandomly();
      return particle;
    },
    setDimensions(w, h) {
      width = w;
      height = h;
    },
  };
}
