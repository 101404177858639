import React from 'react';
import { Router } from '@reach/router';
import Nav from './nav/Nav';
import Home from './home/Home';
import Footer from './footer/Footer';
import styles from './App.module.scss';

function App() {
  return (
    <>
      <Nav />
      <Router className={styles.container}>
        <Home path="/" />
      </Router>
      <Footer />
    </>
  );
}

export default App;
