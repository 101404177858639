import React, { useEffect, useRef } from 'react';
import useWindowSize from '../utils/useWindowSize';
import ParticleFactory from './ParticleFactory';
import styles from './ParticleBoard.module.scss';

const COLOURS = ['gray', 'black'];
const SCALE = 1.5;
const RADIUS = 3;
const VELOCITY = 2.5;
const BATCH_PARTICLES = 8;

function setCanvasSize(canvas, parent) {
  canvas.width = parent.offsetWidth / SCALE;
  canvas.height = parent.scrollHeight / SCALE;
}

/**
 * Particle display that moves towards a targetRef
 * TODO: accept targetX and targetY instead of ref
 *
 * @param {{ targetRef: React.ref }} props
 */
function ParticleBoard(props) {
  const { targetRef } = props;
  const windowSize = useWindowSize();
  const parentRef = useRef();
  const canvasRef = useRef();
  const factoryRef = useRef(ParticleFactory(COLOURS, RADIUS, VELOCITY));
  const pointsRef = useRef([]);

  useEffect(() => {
    const parent = parentRef.current;
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    const points = pointsRef.current;

    const pixelRatio = window.devicePixelRatio;
    context.scale(pixelRatio, pixelRatio);
    context.imageSmoothingEnabled = false;
    // scale and resize the canvas
    canvas.style.transform = `scale(${SCALE})`;
    setCanvasSize(canvas, parent);

    const area = canvas.width * canvas.height;
    const maxParticles = Math.round(Math.sqrt(area) / 7);
    const batches = Math.ceil(maxParticles / BATCH_PARTICLES);
    for (let batch = 0; batch < batches; batch++) {
      requestIdleCallback(() => {
        let i = 0;
        while (i < BATCH_PARTICLES && points.length < maxParticles) {
          const point = factoryRef.current.create(
            batch,
            canvas.width,
            canvas.height,
          );
          points.push(point);
          i++;
        }
      });
    }
  }, []);

  useEffect(
    () => {
      const parent = parentRef.current;
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
      const target = targetRef.current;
      const points = pointsRef.current;

      setCanvasSize(canvas, parent);
      factoryRef.current.setDimensions(canvas.width, canvas.height);

      let targetX;
      let targetY;
      let animationFrameHandler;

      function draw() {
        context.clearRect(0, 0, canvas.width, canvas.height);
        for (let i = 0; i < points.length; i++) {
          const p = points[i];
          p.move(targetX, targetY);
          p.draw(context);
        }
        animationFrameHandler = helper();
      }
      const helper = () => requestAnimationFrame(draw);

      animationFrameHandler = requestAnimationFrame(() => {
        // Get center of target in window
        const targetRect = target.getBoundingClientRect();
        // DEBUG
        // console.table({
        //   rectTop: targetRect.top,
        //   windowScrollX: window.scrollX,
        //   windowScrollY: window.scrollY,
        //   relativeRectTop: targetRect.top + window.scrollY,
        //   offsetTop: target.offsetTop,
        //   offsetLeft: target.offsetLeft,
        //   offsetWidth: target.offsetWidth,
        //   offsetHeight: target.offsetHeight,
        // });
        // console.table({
        //   clientHeight: target.clientHeight,
        //   clientLeft: target.clientLeft,
        //   clientTop: target.clientTop,
        //   clientWidth: target.clientWidth,
        //   scrollHeight: target.scrollHeight,
        //   scrollLeft: target.scrollLeft,
        //   scrollTop: target.scrollTop,
        //   scrollWidth: target.scrollWidth,
        // });
        // console.log(target);
        targetX = targetRect.width / 2 + target.offsetLeft;
        targetY = targetRect.height / 2 + target.offsetTop;
        // Scale position
        targetX /= SCALE;
        targetY /= SCALE;

        animationFrameHandler = helper();
      });

      return () => {
        cancelAnimationFrame(animationFrameHandler);
      };
    },
    [windowSize, targetRef], // Reanimate when window changes
  );

  return (
    <div className={styles.parent} ref={parentRef}>
      <canvas className={styles.canvas} ref={canvasRef} />
    </div>
  );
}

export default ParticleBoard;
