import React from 'react';
import styles from './Footer.module.scss';
/**
 * Static footer
 */
function Footer() {
  return (
    <footer className={styles.footer}>
      <p className={styles.desc}>
        &copy; Stonks Trading 2019-2021
      </p>
    </footer>
  );
}

export default React.memo(Footer);
