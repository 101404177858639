import { useState, useEffect } from 'react';
import supportsPassive from './supportsPassive';

// Keeps track of all setStates available
const setStates = new Set();

// Debounce handler
let resizeTimeout;
function debouncedHandler() {
  if (resizeTimeout) {
    clearTimeout(resizeTimeout);
  }
  resizeTimeout = setTimeout(function() {
    // Calls all setStates available
    setStates.forEach((setState) => {
      setState({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    });
  }, 64);
}

/**
 * Gets the current window size, debounced
 * @param {number?} initialWidth
 * @param {number?} initialHeight
 */
const useWindowSize = (initialWidth = Infinity, initialHeight = Infinity) => {
  const isClient = typeof window === 'object';
  const [state, setState] = useState({
    width: isClient ? window.innerWidth : initialWidth,
    height: isClient ? window.innerHeight : initialHeight,
  });

  useEffect(() => {
    const options = supportsPassive() ? { passive: true } : false;
    // Attach handler if not already present
    if (setStates.size === 0) {
      window.addEventListener('resize', debouncedHandler, options);
    }
    setStates.add(setState);

    return () => {
      // on unmount, remove setState and handler as necessary
      setStates.delete(setState);
      if (setStates.size === 0) {
        window.removeEventListener('resize', debouncedHandler, options);
      }
    };
  }, []);

  return state;
};

export default useWindowSize;
